import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.style';

/** @namespace Scandipwa/Component/ExpandableContent/Component/ExpandableContentComponent */
export class ExpandableContentComponent extends SourceExpandableContent {
    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { isArrow } = this.props;

        /** to add accordion in filter hide below code */
        // const { isArrow, device: { isMobile } } = this.props;
        // if (!isMobile) {
        //     return null;
        // }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }
}

export default ExpandableContentComponent;
