/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { appendWithStoreCode } from 'Util/Url';

export const StoreFinder = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "storeFinder" */ '../route/StoreFinder')
);
export const StoreDetails = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "StoreDetails" */ '../route/StoreDetails')
);

export class RouterPlugin {
    switchItems = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ appendWithStoreCode('stores') } exact component={ StoreFinder } />,
            position: 90
        },
        {
            component: <Route path={ appendWithStoreCode('stores/:identifier') } component={ StoreDetails } />,
            position: 89
        }
    ];
}

const { switchItems } = new RouterPlugin();

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: switchItems
        }
    }
};

export default config;
