import {
    OrderQuery as SourceOrderQuery
} from 'SourceQuery/Order.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Order/Query/OrderQuery */
export class OrderQuery extends SourceOrderQuery {
    getOrderListQuery() {
        return new Field('getOrderList')
            .addFieldList(this._getOrderListFields(true));
    }

    _getOrderByIdField(orderId) {
        return new Field('getOrderById')
            .addArgument('id', 'Int!', parseInt(orderId, 10))
            .addFieldList(this._getOrderItemsFields());
    }

    _getBaseOrderInfoFields(isList) {
        return [
            'id',
            'increment_id',
            'coupon_code',
            'discount_amount',
            'credit_amount',
            'created_at',
            'status',
            'status_label',
            'grand_total',
            'currency_code',
            'small_image',
            'ship_to',
            'bag_id',
            'item_status',
            'item_status_label',
            'product_sku',
            'product_name',
            'product_qty',
            'item_id',
            ...(isList ? [] : ['sub_total'])
        ];
    }

    _getOrderItemsFields(isList) {
        return Array.from(!isList ? [
            this._getBaseOrderInfoField(isList),
            this._getPaymentInfoField(),
            this._prepareShippingInfo(),
            this._getOrderProductsField(),
            this._getFyndOrderDetails()
        ] : [
            'order_increment_id',
            this._getBaseOrderInfoField(isList)]);
    }

    _getOrderProductsFields() {
        return [
            ...this._getDefaultFields(),
            ...this._prepareImageFields(),
            this._prepareAttributes(),
            this.getOrderSelectedOptions()
        ];
    }

    getOrderSelectedOptions() {
        return new Field('selected_options')
            .addFieldList(['label', 'value']);
    }

    getCancelOrderByIdQuery(orderId, item_id, product_qty, bag_id) {
        return new Field('CancelItem')
            .addArgument('orderId', 'Int!', parseInt(orderId, 10))
            .addArgument('itemId', 'Int!', parseInt(item_id, 10))
            .addArgument('qty', 'Int!', parseInt(product_qty, 10))
            .addArgument('bagId', 'String!', bag_id)
            .addFieldList(['message']);
    }

    _getPaymentInfoFields() {
        return [
            'method',
            'method_type',
            this._getAdditionalInformationField()
        ];
    }

    _getFyndOrderDetails() {
        return new Field('fynd_order_details')
            .addFieldList(this._getFyndOrderDetailsField());
    }

    _getFyndOrderDetailsField() {
        return [
            'fynd_order_id',
            'cms_order_id',
            'bag_id',
            'item_fynd_id',
            'shipment_id',
            'invoice_id',
            'item_status',
            'item_status_label',
            'item_rma_status',
            'item_rma_status_label',
            'created_at',
            'updated_at',
            'invoice_pdf',
            'shipment_pdf',
            'cms_order_item_id',
            'track_url'
        ];
    }

    _prepareOrderCustomerAddressInfoFields() {
        return [
            'city',
            'company',
            'firstname',
            'lastname',
            'middlename',
            'telephone',
            'district',
            'house_number',
            'apartment_number',
            'postomat_code',
            'store_pickup_code',
            'post_office_code',
            'postcode',
            'street',
            'is_b2b',
            'region',
            'region_id',
            'country_id'
        ];
    }
}

export default new OrderQuery();
