import ClickOutside from 'Component/ClickOutside';
import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';
import history from 'Util/History';

import { ESCAPE_KEY } from './Popup.config';

import './Popup.override.style';

/** @namespace Scandipwa/Component/Popup/Component/PopupComponent */
export class PopupComponent extends SourcePopup {
    // TODO implement logic

    hidePopUp = () => {
        const { hideActiveOverlay, goToPreviousNavigationState, onClose } = this.props;
        const isVisible = this.getIsVisible();

        if (isVisible) {
            this.unfreezeScroll();
            hideActiveOverlay();
            goToPreviousNavigationState();
            onClose();
        }
    };

    hidePopupAndGoBack = () => {
        this.hidePopUp();
        window.history.pushState(
            {},
            '', `${location.pathname}`
        );
        history.goBack();
    };

    handleClickOutside = () => {
        const { clickOutside } = this.props;
        if (!clickOutside) {
            return;
        }
        this.hidePopupAndGoBack();
    };

    handleKeyDown = (e) => {
        switch (e.keyCode) {
        case ESCAPE_KEY:
            this.hidePopupAndGoBack();
            break;
        default:
            break;
        }
    };

    renderContent() {
        const { children, contentMix } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="Content" mix={ contentMix }>
                    <header block="Popup" elem="Header">
                    { this.renderTitle() }
                    { this.renderCloseButton() }
                    </header>
                    { this.renderNotifications() }
                    { children }
                </div>
            </ClickOutside>
        );
    }
}

export default PopupComponent;
