import PropTypes from 'prop-types';

import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';

import LoaderImage from './proline.gif';

import './Loader.override.style';

/** @namespace Scandipwa/Component/Loader/Component/LoaderComponent */
export class LoaderComponent extends SourceLoader {
    static propTypes = {
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        isLoading: true
    };

    renderMain() {
        return (
            <div block="Loader" elem="Proline">
                <img src={ LoaderImage } alt="loader" />
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader">
                <div block="Loader" elem="Scale">
                    { this.renderMain() }
                </div>
            </div>
        );
    }
}

export default LoaderComponent;
